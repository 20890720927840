import React from 'react'
import {Link, graphql, useStaticQuery} from 'gatsby'
import Head from '../components/head'
import {post, posts} from './blog.module.scss'
import Layout from '../components/layout'

const BlogPage = () => {
    const postList = useStaticQuery(graphql`
        query {
            allMarkdownRemark {
                edges {
                    node {
                        fields {
                            slug
                        }
                        frontmatter {
                            title
                            date
                        }
                        html
                    }
                }
            }
        }
    `).allMarkdownRemark.edges

    return (
        <Layout>
            <Head title="Blog" url="/blog/"/>
            <h1>Posts</h1>
            <ol className={posts}>
                {postList.map((p, index) => {
                    return (
                        <li key={index} className={post}>
                            <h2><Link to={`/blog/${p.node.fields.slug}`}>{p.node.frontmatter.title}</Link></h2>
                            <p>{p.node.frontmatter.date}</p>
                        </li>
                    )
                })}
            </ol>
        </Layout>
    )
}

export default BlogPage